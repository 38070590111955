<template>
  <v-container id="dashboard-view" fluid tag="section">
    <default-loader-page v-if="loading" />
    <default-ula />
    <v-row>
      <v-col cols="12">
        <default-stats-top-card :stats="currentYearStats" :title="statTitle" />
      </v-col>
      <v-col cols="12">
        <default-stats-top-card
          :stats="stats[2]"
          title="Количество нефтянных организаций"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> <default-map-container /></v-col>
      <v-col cols="12"> <default-chart-container /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get } from "vuex-pathify";
import { startOfYear, formatISO } from "date-fns";

export default {
  name: "DashboardView",
  components: {
    DefaultMapContainer: () => import("@/components/map/MapContainer.vue"),
    DefaultChartContainer: () =>
      import("@/components/chart/ChartContainer.vue"),
    DefaultStatsTopCard: () => import("@/views/Dashboard/StatsTopCard.vue"),
    DefaultLoaderPage: () => import("@/components/LoaderPage.vue"),
    DefaultUla: () => import("@/components/ULA.vue")
  },

  computed: {
    stats: get("stats/stats")
  },
  data() {
    return {
      loading: true,
      currentYearStats: [],
      unit: " т.",
      statTitle: `Общий оборот нефти за ${new Date().getFullYear()} год`
    };
  },
  methods: {
    async initialize() {
      const URLs = [
        "api/Report/Transport/1", //DeliveryFromOilProduction
        "api/Report/Transport/2", //ShipmentToOilProduction
        "api/Report/Transport/4" //ShipmentToExport
      ];
      const params = {
        organizationId: 0,
        date_from: formatISO(startOfYear(new Date()), {
          representation: "date"
        }),
        date_to: formatISO(new Date(), { representation: "date" })
      };
      const currentYearStatsValues = [];

      const fetchURL = url => this.$http.get(url, { params });

      const promiseArray = URLs.map(fetchURL);

      Promise.all(promiseArray)
        .then(data => {
          data.forEach(response => {
            currentYearStatsValues.push(this.getValue(response.data));
          });
        })
        .catch(err => {})
        .finally(() => {
          this.currentYearStats = this.stats[1].map((element, index) => {
            element.value =
              currentYearStatsValues[index].toLocaleString() + this.unit;
            return element;
          });
          this.loading = false;
        });
    },
    getValue: data =>
      data.reduce((sum, current) => sum + parseInt(current.mass, 10), 0)
  },
  async created() {
    await this.initialize();
  }
};
</script>
